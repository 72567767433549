<template>
  <div>
    <div
      v-if="
        currentUserRole === 'ROLE_ROOT' ||
        currentUserRole === 'ROLE_SUPER_ADMIN'
      "
      class="row"
    >
      <div class="col-md-12">
        <div class="card card-custom gutter-b">
          <div class="card-body">
            <div class="form-group mb-0">
              <vSelect
                v-model="selectedCompany"
                @input="onCompanySelect"
                class="form-control form-control-solid form-control--company-select"
                placeholder="Select company"
                :options="companies"
                :reduce="(company) => company['oib']"
                label="name"
              >
                <template #option="{ name }">
                  <span class="mr-3">{{ name }}</span>
                </template>
              </vSelect>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="card card-custom gutter-b">
          <div class="card-header border-0">
            <h3 class="card-title font-weight-bolder text-dark">
              {{ $t('COMPANIES.COMPANY_DAILY_REVENUE') }}
            </h3>
            <div class="card-toolbar"></div>
          </div>
          <div class="card-body pt-0">
            <div class="row">
              <div class="col-md-12">
                <div v-if="!isTransactionsLoaded" class="col-12 text-center my-5 p-2 pt-5 bg-secondary">
                  <div class="text-center my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-4">{{ $t('GENERAL.LOADING') }}</strong>
                  </div>
                </div>
                <div
                  v-if="isTransactionsLoaded"
                  class="table-responsive mb-0 pb-0"
                >
                  <table
                    class="table table-head-custom table-vertical-center table-head-bg"
                  >
                    <thead>
                      <tr class="text-uppercase">
                        <th class="pl-6">
                          <span>{{ $t('GENERAL.DATE') }}</span>
                        </th>
                        <th>
                          <span>{{ $t('GENERAL.TOTAL') }}</span>
                        </th>
                        <th>
                          <span>{{ $t('COMPANIES.TRANSACTIONS_NUMBER') }}</span>
                        </th>
                        <th class="text-right pr-6" style="min-width: 240px">
                          {{ $t('GENERAL.ACTIONS') }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(transaction, key) in transactions" :key="key">
                        <td
                          class="pl-6 text-capitalize"
                          :class="{ 'border-top-0': key === 0 }"
                        >
                          {{ transaction.date | formatDate }}
                          {{ getDayNameFromDate(transaction.date) }}
                        </td>
                        <td :class="{ 'border-top-0': key === 0 }">
                          <span
                            v-if="transaction.total > 0"
                            class="text-dark font-weight-bold"
                            >{{ transaction.total | currencyFormat }}</span
                          >
                          <span v-else class="text-dark font-weight-bold"
                            >0,00 €</span
                          >
                        </td>
                        <td :class="{ 'border-top-0': key === 0 }">
                          <span
                            class="label label-rounded label-primary px-5"
                            >{{ transaction.transactions }}</span
                          >
                        </td>
                        <td
                          class="text-right pr-6"
                          :class="{ 'border-top-0': key === 0 }"
                        >
                          <button
                            @click="getTransactions(transaction.date)"
                            class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon"
                            v-b-tooltip.hover.top="$t('COMPANIES.SHOW_TRANSACTIONS')"
                          >
                            <span class="svg-icon svg-icon-md">
                              <inline-svg
                                src="media/svg/icons/Text/Align-justify.svg"
                              />
                            </span>
                          </button>
                          <button
                            v-if="
                              currentUserRole === 'ROLE_ROOT' ||
                              currentUserRole === 'ROLE_SUPER_ADMIN'
                            "
                            @click="deleteEntity(transaction.id)"
                            class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon ml-3"
                          >
                            <span class="svg-icon svg-icon-md">
                              <inline-svg
                                src="media/svg/icons/General/Trash.svg"
                              />
                            </span>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <span v-else-if="isTransactionsLoaded && transactions.length === 0" class="text-muted font-weight-500">No transactions</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <b-modal id="daily_transactions" size="xl" hide-header hide-footer>
          <div v-if="isDailyTransactionsLoaded">
            <b-button
              class="btn btn-light-primary mb-3"
              block
              @click="$bvModal.hide('daily_transactions')"
              >{{ $t('GENERAL.CLOSE') }}</b-button
            >
            <div class="table-responsive mb-0 pb-0">
              <table
                class="table table-head-custom table-vertical-center table-head-bg"
              >
                <thead>
                  <tr class="text-uppercase">
                    <th class="pl-6">{{ $t('COMPANIES.DEVICE_NAME') }}</th>
                    <th>Imei</th>
                    <th>{{ $t('GENERAL.LOCATION') }}</th>
                    <th>{{ $t('GENERAL.AMOUNT') }}</th>
                    <th class="text-right">{{ $t('GENERAL.PAYMENT_TYPE') }}</th>
                    <th class="text-right pr-6">
                      {{ $t('GENERAL.DATE_AND_TIME') }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(dailyTransaction, key) in dailyTransactions"
                    :key="key"
                  >
                    <td class="pl-6" :class="{ 'border-top-0': key === 0 }">
                      {{ dailyTransaction.deviceMeta.name }}
                    </td>
                    <td :class="{ 'border-top-0': key === 0 }">
                      {{ dailyTransaction.imei }}
                    </td>
                    <td :class="{ 'border-top-0': key === 0 }">
                      {{ dailyTransaction.locationMeta.title }}
                    </td>
                    <td :class="{ 'border-top-0': key === 0 }">
                      <span>{{
                        new Intl.NumberFormat('hr-HR', {
                          style: 'currency',
                          currency: 'EUR',
                        }).format(dailyTransaction.amount)
                      }}</span>
                    </td>
                    <td
                      class="text-right"
                      :class="{ 'border-top-0': key === 0 }"
                    >
                      <span
                        class="label label-lg label-inline label-light-primary"
                        >{{
                          mapPaymentType(dailyTransaction.paymentType)
                        }}</span
                      >
                    </td>
                    <td
                      class="text-right"
                      :class="{ 'border-top-0': key === 0 }"
                    >
                      <span>{{
                        dailyTransaction.createdAt | formatDateTime
                      }}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div v-else class="col-12 text-center my-5 pt-5 pb-2">
            <b-spinner variant="primary"></b-spinner>
          </div>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import { mapGetters } from 'vuex'
import ApiService from '@/core/services/api.service'
import vSelect from 'vue-select'
import { getDayNameFromDate } from '@/core/helpers/helpers'

export default {
  name: 'CompanyDailyRevenue',
  components: {
    vSelect,
  },
  data() {
    return {
      companies: [],
      isCompaniesLoaded: false,
      selectedCompany: null,
      transactions: [],
      isTransactionsLoaded: false,
      dailyTransactions: [],
      isDailyTransactionsLoaded: false,
      isDailyTransactionsStartLoaded: false,
    }
  },
  computed: {
    ...mapGetters([
      'currentUserRole',
      'currentUserCompany',
      'currentUserCompanyOib',
    ]),
  },
  mounted() {
    console.log(this.$i18n.locale)
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Daily Revenue', route: '' },
    ])

    if (
      this.currentUserRole === 'ROLE_ADMIN' ||
      this.currentUserRole === 'ROLE_WORKER' ||
      this.currentUserRole === 'ROLE_JUNIOR'
    ) {
      ApiService.get(
        'company_transactions',
        `?oib=${this.currentUserCompanyOib}&order[date]=desc&pagination=false`
      ).then(({ data }) => {
        this.transactions = [...data['hydra:member']]
        this.isTransactionsLoaded = true
      })
    } else {
      this.getCompanies('companies').then(({ data }) => {
        this.companies = [...data['hydra:member']]
        this.isCompaniesLoaded = true
      })
    }
  },
  methods: {
    mapPaymentType(paymentType) {
      switch (paymentType) {
        case 'pay_coin':
        case 'pay_cctalk':
        case 'pay_mdb':
          return 'COIN'
        case 'pay_sms':
          return 'SMS'
        case 'pay_card':
          return 'CARD'
        case 'pay_token':
          return 'TOKEN'
        default:
          return ''
      }
    },
    getDayNameFromDate(date) {
      return getDayNameFromDate(date, this.$i18n.locale)
    },
    async getCompanies(resource, slug = '') {
      try {
        return await ApiService.get(resource, slug)
      } catch (e) {
        throw new Error(e)
      }
    },
    getTransactions(date) {
      this.dailyTransactions = []
      this.isDailyTransactionsLoaded = false
      this.isDailyTransactionsStartLoaded = true

      this.$root.$emit('bv::show::modal', 'daily_transactions')

      if (this.currentUserRole === 'ROLE_ADMIN') {
        ApiService.get(
          'transactions',
          `?oib=${this.currentUserCompanyOib}&createdAt=${date.slice(
            0,
            10
          )}&pagination=false`
        ).then(({ data }) => {
          this.dailyTransactions = [...data['hydra:member']]
          this.isDailyTransactionsLoaded = true
          this.isDailyTransactionsStartLoaded = false
        })
      }
    },
    deleteEntity(id) {
      let confirmation = confirm('Do you want to delete this day revenue?')
      if (confirmation === false) return

      let confirmationSecond = confirm('Are you sure?')
      if (confirmationSecond === false) return

      this.isTransactionsLoaded = false

      ApiService.delete(`company_transactions/${id}`).then(() => {
        this.$notify({
          group: 'notification',
          type: 'success',
          title: 'Day revenue successfully deleted',
        })

        if (
          this.currentUserRole === 'ROLE_ADMIN' ||
          this.currentUserRole === 'ROLE_WORKER' ||
          this.currentUserRole === 'ROLE_JUNIOR'
        ) {
          ApiService.get(
            'company_transactions',
            `?oib=${this.currentUserCompanyOib}&order[date]=desc&pagination=false`
          ).then(({ data }) => {
            this.transactions = [...data['hydra:member']]
            this.isTransactionsLoaded = true
          })
        }

        ApiService.get(
          'company_transactions',
          `?oib=${this.selectedCompany}&order[date]=desc&pagination=false`
        ).then(({ data }) => {
          this.transactions = [...data['hydra:member']]
        })
      })
    },
    onCompanySelect() {
      this.transactions = []
      this.isTransactionsLoaded = false

      ApiService.get(
        'company_transactions',
        `?oib=${this.selectedCompany}&order[date]=desc&pagination=false`
      ).then(({ data }) => {
        this.transactions = [...data['hydra:member']]
        this.isTransactionsLoaded = true
      })
    },
  },
}
</script>

<style lang="scss">
@import 'vue-select/src/scss/vue-select.scss';

.form-control {
  &--company-select {
    height: unset !important;

    .vs__dropdown-toggle {
      padding: 0;
      border: 0;
      border-radius: 0;
    }
    .vs__dropdown-menu {
      border: 1px solid #e4e6ef;
      -webkit-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
      box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);

      /* width */
      &::-webkit-scrollbar {
        width: 4px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      li {
        padding: 0.65rem 1rem;

        &:hover {
          background: #f3f6f9;
          color: #3f4254;
        }
      }
    }

    .vs__clear {
      display: none;
    }
    .vs__selected {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 0.25rem 0.75rem;
      font-size: 1rem;
      margin: 0.1rem 0.4rem 0.1rem 0;
      position: relative;
      float: left;
      color: #3f4254;
      background: #f3f6f9;
      border: 0;
      border-radius: 0.42rem;
    }
    .vs__dropdown-option--highlight {
      background: #ebedf3;
      color: #3f4254;
    }
  }
}
</style>
